
import Sheet from '@/layouts/Sheet.vue'
import TripMonitoringTable from './TripMonitoringTable.vue'
import TripMonitoringCallModal from './TripMonitoringCallModal.vue'
import CRIcon from '@/cr/components/CRIcon.vue'

import { DateTime } from 'luxon'
import { getTripMonitoringReferrals } from '@/services/reservations'
import { EventBus } from '@/utils/event-bus'
import CRSelect from '@/components/CRSelect.vue'
import { TripMonitoringReferral, TripMonitoringStatusLevel, TripMonitoringStatus } from '@/models/dto/TripMonitoring'

export default {
  components: {
    Sheet,
    TripMonitoringTable,
    CRSelect,
    CRIcon,
    TripMonitoringCallModal
  },
  data() {
    return {
      selectedCards: [],
      referrals: [],
      filterDriverIssues: false,
      filterTrackingIssues: false,
      filterVehicleIssues: false,
      selectedReferralIdsSet: new Set(),
      currentTableItems: [],
      assignmentFilter: null,
      isAICallModalOpen: false,
    }
  },
  computed: {
    filteredReferrals(): TripMonitoringReferral[] {
      return this.referrals.filter(
        (referral: TripMonitoringReferral) =>
          (this.filterDriverIssues ? this.isNotSuccessful(referral.driverStatus) : true) &&
          (this.filterTrackingIssues ? this.isNotSuccessful(referral.trackingStatus) : true) &&
          (this.filterVehicleIssues ? this.isNotSuccessful(referral.vehicleStatus) : true) &&
          (this.assignmentFilter === null || referral.assignedTo === this.assignmentFilter)
      )
    },
    driverIssueCount(): number {
      return this.filteredReferrals
        .filter((referral: TripMonitoringReferral) => this.isNotSuccessful(referral.driverStatus))
        .length
    },
    vehicleIssueCount(): number {
      return this.filteredReferrals
        .filter((referral: TripMonitoringReferral) => this.isNotSuccessful(referral.vehicleStatus))
        .length
    },
    trackingIssueCount(): number {
      return this.filteredReferrals
      .filter((referral: TripMonitoringReferral) => this.isNotSuccessful(referral.trackingStatus))
      .length
    },
    currentVisibleSelectedReferralIdsSet() {
      const currentVisibleSelectedReferralIdsSet = new Set()

      for (const tableItem of this.currentTableItems) {
        if (this.selectedReferralIdsSet.has(tableItem.reservationId)) {
          currentVisibleSelectedReferralIdsSet.add(tableItem.reservationId)
        }
      }

      return currentVisibleSelectedReferralIdsSet
    },
    currentSelectedReferrals() {
      return this.referrals.filter((referral) => this.currentVisibleSelectedReferralIdsSet.has(referral.reservationId))
    },
    callModalKey() {
      const referrals = this.currentSelectedReferrals.map((referral) => referral.reservationId).join('-')
      return `call-modal-${referrals}-${this.isAICallModalOpen}`
    },
    assignmentFilterItems() {
      const assignmentFilterItems = [
        {
          text: 'All Assignments',
          value: null,
        },
        {
          text: 'Unassigned Trips',
          value: '',
        }
      ]
      const userNameAssignmentItems = []
      const seenAssignmentNames = new Set()
      seenAssignmentNames.add('')
      for(const referral of this.referrals) {
        if (!seenAssignmentNames.has(referral.assignedTo)) {
          userNameAssignmentItems.push(
            {
              text: `${referral.assignedTo}'s trips`,
              value: referral.assignedTo,
            }
          )
          seenAssignmentNames.add(referral.assignedTo)
        }
      }
      userNameAssignmentItems.sort(
        (a, b) => a.value.localeCompare(b.value)
      )

      return [...assignmentFilterItems, ...userNameAssignmentItems]
    }
  },
  methods: {
    toggleIssueFilter(type) {
      if (this.selectedCards.includes(type)) {
        this.selectedCards = this.selectedCards.filter((card) => card !== type)
      } else {
        this.selectedCards.push(type)
      }

      if (type === 'driver') this.filterDriverIssues = !this.filterDriverIssues
      else if (type === 'tracking')
        this.filterTrackingIssues = !this.filterTrackingIssues
      else if (type === 'vehicle')
        this.filterVehicleIssues = !this.filterVehicleIssues
    },
    async fetchReferrals() {
      const now = DateTime.now().setZone('America/New_York');
      const startOfDay = now.startOf('day')
      const endOfDay = now.endOf('day')

      const startOfNextDay = startOfDay.plus({ days: 1 })
      const endOfNextDay = endOfDay.plus({ days: 1 })

      const startOfNextDayUTC = startOfNextDay
        .toUTC()
        .toFormat('yyyy-MM-dd HH:mm:ss')
      const endOfNextDayUTC = endOfNextDay
        .toUTC()
        .toFormat('yyyy-MM-dd HH:mm:ss')

      const tableResp = await getTripMonitoringReferrals(
        startOfNextDayUTC,
        endOfNextDayUTC
      )
      this.referrals = tableResp.data.referrals
    },
    getTomorrowDate() {
      return DateTime.now().setZone('America/New_York').plus({ days: 1 }) .toFormat('MM/dd/yyyy');
    },
    handleToggleReferral(referralId) {
      if (this.selectedReferralIdsSet.has(referralId)) {
        this.selectedReferralIdsSet.delete(referralId)
      } else {
        this.selectedReferralIdsSet.add(referralId)
      }
      this.selectedReferralIdsSet = new Set(this.selectedReferralIdsSet)
    },
    handleCurrentItemsChange(currentItems) {
      this.currentTableItems = currentItems
    },
    handleToggleAllVisibleReferrals() {
      if (this.currentTableItems.length === this.currentVisibleSelectedReferralIdsSet.size) {
        this.clearVisibleSelection()
      } else {
        for (const tableItem of this.currentTableItems) {
          this.selectedReferralIdsSet.add(tableItem.reservationId)
        }
        this.selectedReferralIdsSet = new Set(this.selectedReferralIdsSet)
      }
    },
    clearVisibleSelection() {
      for (const tableItem of this.currentTableItems) {
          this.selectedReferralIdsSet.delete(tableItem.reservationId)
      }

      this.selectedReferralIdsSet = new Set(this.selectedReferralIdsSet)
    },
    isNotSuccessful(status: TripMonitoringStatus) {
      return status.statusLevel !== TripMonitoringStatusLevel.Success
    },
    openAICallModal() {
      this.isAICallModalOpen = true
    },
  },
  created() {
    EventBus.$on('global-table-view-refresh', async (message) => {
      await this.$store.dispatch('app/closeDialog')
      this.$store.dispatch('app/showAlert', {
          message
      })
      this.clearVisibleSelection()
      await this.fetchReferrals()
    })
  },
  async mounted() {
    await this.fetchReferrals()
  },
  beforeDestroy() {
    EventBus.$off('global-table-view-refresh')
  }
}

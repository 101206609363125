
import { TripMonitoringReferral } from '@/models/dto/TripMonitoring'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { getCompanyContactInfo } from '@/services/tripMonitoring'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { isRequired } from '@/utils/validators'
import { createOperatorAssignmentCall } from '@/services/callRequest'

@Component({})
export default class TripMonitoringCallModal extends Vue {
  @Prop({ required: true }) value!: boolean
  @Prop({ required: true, type: Array }) referrals!: TripMonitoringReferral[]

  @Watch('companyId')
  async onCompanyIdChange() {
    await this.loadCompanyContactInfo()
  }

  @Watch('value')
  onModalVisibilityChange(newValue: boolean) {
    if (!newValue) {
      this.operatorPhoneNumbers = []
      this.selectedPhoneNumber = null
      this.contactPreferences = ''
    }
  }

  contactPreferences = ''
  operatorPhoneNumbers: { text: string, value: string, rawNumber: string }[] = []
  selectedPhoneNumber: { text: string, value: string, rawNumber: string } | null = null
  isSubmitting = false
  isLoadingOperatorInfo = false
  phoneUtil = PhoneNumberUtil.getInstance()
  isRequired = isRequired


  get operatorName(): string {
    return this.referrals[0]?.companyName
  }

  get companyId(): number {
    return this.referrals[0]?.companyId
  }

  formatPhoneNumber(phoneNumber: string): string {
    try {
      const parsedNumber = this.phoneUtil.parse(phoneNumber, 'US')

      if (!this.phoneUtil.isValidNumber(parsedNumber)) {
        return phoneNumber
      }

      return this.phoneUtil.format(parsedNumber, PhoneNumberUtil.NATIONAL)
    } catch (e) {
      return phoneNumber
    }
  }

  /**
   * For use in the :rules prop in the CRSelect that selects an operator phone number to call
   *
   * @param phoneNumber
   * @returns boolean | string
   *
   */
  validatePhoneNumber(phoneNumber: { text: string, value: string, rawNumber: string }): boolean | string {
    const parsedNumber = this.phoneUtil.parse(phoneNumber.rawNumber, 'US')
    if (this.phoneUtil.isValidNumber(parsedNumber)) {
      return true
    }
    return 'Invalid phone number'
  }

  // Load contact info for the operator of the selected referrals
  // Populate the select with the operator's phone numbers
  // Populate the contact preferences text
  async loadCompanyContactInfo(): Promise<void> {
    if (!this.companyId) {
      return
    }
    this.isLoadingOperatorInfo = true

    try {
      const { data } = await getCompanyContactInfo(this.companyId)
      const { opsPhone, partnerPhone, phone, smsPhone, contactPreferenceText } = data

      if (phone) {
        this.operatorPhoneNumbers.push({ text: `${this.formatPhoneNumber(phone)}`, value: `${phone}-primary`, rawNumber: phone })
      }
      if (smsPhone) {
        this.operatorPhoneNumbers.push({ text: `${this.formatPhoneNumber(smsPhone)} (SMS)`, value: `${smsPhone}-sms`, rawNumber: smsPhone })
      }
      if (opsPhone) {
        this.operatorPhoneNumbers.push({ text: `${this.formatPhoneNumber(opsPhone)} (Ops)`, value: `${opsPhone}-ops`, rawNumber: opsPhone })
      }
      if (partnerPhone) {
        this.operatorPhoneNumbers.push({ text: `${this.formatPhoneNumber(partnerPhone)} (Partner)`, value: `${partnerPhone}-partner`, rawNumber: partnerPhone })
      }

      if (contactPreferenceText) {
        this.contactPreferences = contactPreferenceText
      }
    } catch(e) {
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: 'Error loading operator contact info',
      })
      this.$emit('input', false)
    } finally {
      this.isLoadingOperatorInfo = false
    }
  }

  async submit(): Promise<void> {
    this.isSubmitting = true

    if (!(this.$refs['phone-form'] as any).validate()) {
      this.isSubmitting = false
      return
    }

    const payload = {
      phone: this.selectedPhoneNumber.rawNumber,
      referralIds: this.referrals.map(({ reservationId }) => reservationId),
    }

    // TODO: Replace this with the following call + error handling once the endpoint is active
    // await createOperatorAssignmentCall(payload)
    await new Promise((resolve) => setTimeout(resolve, 1000))

    console.log("> Calling operator assignment with:", payload)

    this.$store.dispatch('app/showAlert', {
      type: 'success',
      message: 'Successfully requested AI Call',
    })
    this.isSubmitting = false
    this.$emit('input', false)
  }

  async mounted() {
    await this.loadCompanyContactInfo()
  }
}
